<template>
    <modal ref="modalTrasladarCarpeta" titulo="Trasladar Carpeta" no-aceptar adicional="Trasladar" @adicional="trasladarPromocion">
        <div class="row mx-0 justify-center">
            <div class="col-8 f-15 text-general">
                Selecciona la carpeta a la cual vas a <br /> trasladar esta promoción
            </div>
            <div class="col-8 mt-3">
                <small class="pl-3 text-general">
                    Carpeta
                </small>
                <el-select v-model="id_nueva_carpeta" size="small" class="w-100">
                    <el-option
                    v-for="item in carpetasPromos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
        </div>
    </modal>
</template>

<script>
import Carpetas from '~/services/promociones/promociones_carpetas_admin'
export default {
    data(){
        return {
            value: '',
            id_nueva_carpeta: null,
            id_promocion: this.$route.params.idPromocion,
            id_grupo: this.$route.params.idGrupo,
            carpetasPromos: []
        }
    },
    methods: {
        toggle(){
            this.id_nueva_carpeta = null
            this.value= ''
            this.listarCarpetasPromociones();
            this.$refs.modalTrasladarCarpeta.toggle();
        },
        async trasladarPromocion(){
            try {
                const { data } = await Carpetas.trasladarPromoDeCarpeta(this.id_promocion, this.id_nueva_carpeta)
                this.$refs.modalTrasladarCarpeta.toggle();
                this.$router.push({name: 'admin.promociones.grupos'})
                this.notificacion('Éxito', data.mensaje, 'success')
            } catch (error){
                return this.error_catch(error)
            }
        },
        async listarCarpetasPromociones(){
            try {
                const { data } = await Carpetas.getListaCarpetasPromociones();
                this.carpetasPromos = data.listaCarpetas;
                this.index = this.carpetasPromos.findIndex(x => x.id == this.id_grupo)
                this.carpetasPromos.splice(this.index, 1)
            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>